.data-table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    max-width: 95%;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .data-table-header {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .row-hit {
    background-color: #d4edda !important;
    color: #155724;
  }
  
  .MuiDataGrid-root {
    background-color: white;
    border-radius: 8px;
  }
  
  .MuiDataGrid-cell {
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .MuiDataGrid-root {
      font-size: 0.85rem;
    }
  }